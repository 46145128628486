export default {
  Properties: {
    WidgetList: [
      {
        Type: 'OverdueInvoices',
      },
      {
        Type: 'AverageHourlyRate',
      },
      {
        Type: 'InvoicedPerMonth',
      },
      {
        Type: 'PlannedAbsence',
      },
      {
        Type: 'ProjectEconomyHealth',
      },
    ],
    IsReportWidgetActive: true,
  },
  Links: [
    {
      Href: 'http://localhost/timelog/api/v2/widget/all-active-widgets',
      Rel: 'self',
    },
  ],
};
