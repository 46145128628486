export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        CustomerId: 0,
        CustomerNo: '',
        CustomerName: '',
        ProjectId: 69,
        ProjectNo: '',
        ProjectName: '',
        SumBudgetHours: 0,
        SumHoursSpent: 0,
        CompletionRate: 0,
        ProgressStatus: 'Success',
      },
      {
        CustomerId: 1,
        CustomerNo: '10020000',
        CustomerName: 'TimeLog A/S ABC',
        ProjectId: 71,
        ProjectNo: '2003-0002',
        ProjectName: '2003-0002 Intern',
        SumBudgetHours: 0,
        SumHoursSpent: 825.5,
        CompletionRate: 0,
        ProgressStatus: 'Success',
      },
      {
        CustomerId: 1,
        CustomerNo: '10020000',
        CustomerName: 'TimeLog A/S ABC',
        ProjectId: 72,
        ProjectNo: '2003-0003',
        ProjectName: '2003-0003 Ledelse',
        SumBudgetHours: 0,
        SumHoursSpent: 418,
        CompletionRate: 0,
        ProgressStatus: 'Success',
      },
      {
        CustomerId: 1,
        CustomerNo: '10020000',
        CustomerName: 'TimeLog A/S ABC',
        ProjectId: 73,
        ProjectNo: '2003-0004',
        ProjectName: '2003-0004 Hostingmiljø',
        SumBudgetHours: 0,
        SumHoursSpent: 6.5,
        CompletionRate: 0,
        ProgressStatus: 'Success',
      },
    ],
    Type: 'ProjectEconomyHealth',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/project-economy-health-invoice-widget-data?siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
