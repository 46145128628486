import { MuiDataGrid } from '@timelog/ui-library';
import ReportingWidgetCard from 'src/components/ReportingWidgetCard/ReportingWidgetCard';
import Loading from 'src/components/MilestoneFeed/components/Loading/Loading';
import Error from 'src/components/MilestoneFeed/components/Error/Error';

interface IReportingWidgetDataGrid {
  data: {}[];
  columnData: {}[];
}

interface IReportingWidgetWithTable {
  name: string;
  data: {}[];
  columnData: {}[];
  isEmpty: boolean;
  isLoading: boolean;
  isError: boolean;
}

const rowDataParser = (data: any) => {
  // DRO: id is MANDATORY for datagrid.
  const rowResult = data.map((item: any, index: any) => ({ id: index, ...item }));
  return rowResult;
};

const columnDataParser = (data: any) => {
  const colResult = data.map((item: any) => ({
    field: item.key,
    headerName: item.headerName,
    sortable: false,
    disableReorder: true,
    ...item,
  }));

  return colResult;
};

const ReportDataGrid = ({ data, columnData }: IReportingWidgetDataGrid) => {
  const rows = rowDataParser(data);
  const cols = columnDataParser(columnData);
  return (
    <MuiDataGrid rows={rows} columns={cols} hideFooter disableColumnMenu disableColumnResize />
  );
};

export const ReportingWidgetWithTable = ({
  name,
  data,
  columnData,
  isEmpty,
  isLoading,
  isError,
}: IReportingWidgetWithTable) => {
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  return (
    <ReportingWidgetCard name={name}>
      {isEmpty ? <div /> : <ReportDataGrid data={data} columnData={columnData} />}
    </ReportingWidgetCard>
  );
};

export default ReportingWidgetWithTable;
