import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetInvoicedPerMonthReportingWidget } from 'src/apis/reportingWidgetsAPI';
import ReportingWidgetWithChart from '../ReportingWidgetWithChart';

const parseChartData = (data: any) => {
  const chartData = data.map((item: any) => {
    const chartObject = {
      value: item.month,
      [item.year]: item.invoicedAmount,
      [item.lastYear]: item.invoicedAmountLastYear,
    };
    return chartObject;
  });

  return chartData;
};

const getBarProps = (data: any, key: string, key2: string) => {
  const dataKeyList = data.map((item: any) => item[key]);
  const dataKey2List = data.map((item: any) => item[key2]);
  const sortedDataKeyCollection = dataKeyList.concat(dataKey2List).sort();
  // to remove duplicates
  const barProps: string[] = Array.from(new Set(sortedDataKeyCollection));

  return barProps;
};

export const InvoicedPerMonthReportingWidget = () => {
  const X_AXIS_DATAKEY = 'value';
  const { t } = useTranslation('reportingWidgets');
  const { widgetName, widgetData, isError, isLoading } = useGetInvoicedPerMonthReportingWidget();
  return (
    <ReportingWidgetWithChart
      name={translationAnyText(t, widgetName)}
      data={parseChartData(widgetData)}
      xAxisDataKey={X_AXIS_DATAKEY}
      yAxisDataKey=""
      barPropsDataKey={getBarProps(widgetData, 'year', 'lastYear')}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default InvoicedPerMonthReportingWidget;
