export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12223,
        ProjectNo: 'P17.0084',
        ProjectName: '_TIMJenm002EURE',
        InvoiceNo: 'F1721029',
        InvoiceAmount: 378642.95,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12224,
        ProjectNo: 'P17.0085',
        ProjectName: '_TIMJenm003EUR',
        InvoiceNo: 'F1721030',
        InvoiceAmount: 378642.95,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12227,
        ProjectNo: 'P17.0088',
        ProjectName: '_TIMJenm004EUR',
        InvoiceNo: 'F1721032',
        InvoiceAmount: 378643.76,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12233,
        ProjectNo: 'P17.0094',
        ProjectName: '_TIMJenm006EUR',
        InvoiceNo: 'F1721034',
        InvoiceAmount: 378884.69,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12236,
        ProjectNo: 'P17.0097',
        ProjectName: '_TIMJenm007EUR',
        InvoiceNo: 'F1721035',
        InvoiceAmount: 378643.82,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
      },
    ],
    Type: 'OverdueInvoices',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/overdue-invoice-widget-data?siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
