import cx from 'classnames';
import styles from './ProgressBar.module.scss';

type TProgressBarStatus = 'default' | 'success' | 'error';

export interface IProgressBar {
  value: number;
  barStatus?: TProgressBarStatus;
  label?: string;
  showValue?: boolean;
}

export const ProgressBar = ({ value, barStatus = 'default', label, showValue }: IProgressBar) => (
  <div className={cx(styles.wrapper, { [styles.showValue]: showValue })}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    {label && <label htmlFor="progress-bar">{label}</label>}
    <progress
      className={cx({ [styles[barStatus]]: barStatus })}
      id="progress-bar"
      value={value}
      max="100"
    >
      {value} %
    </progress>
  </div>
);

export default ProgressBar;
