import { CardContent, CardHeader, MuiIconButton, Card } from '@timelog/ui-library';
import { Info, MoreVert } from '@mui/icons-material';
import { ReactNode } from 'react';

interface IReportingWidgetCard {
  name: string;
  children?: ReactNode;
}

export const ReportingWidgetCard = ({ name: title, children }: IReportingWidgetCard) => (
  <Card variant="outlined" sx={{ height: '100%' }}>
    <CardHeader
      title={title}
      action={
        <>
          <MuiIconButton size="small" aria-label="info" css={undefined}>
            <Info />
          </MuiIconButton>
          <MuiIconButton size="small" aria-label="action" css={undefined}>
            <MoreVert />
          </MuiIconButton>
        </>
      }
    />
    <CardContent>{children}</CardContent>
  </Card>
);

export default ReportingWidgetCard;
