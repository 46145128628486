import { useGetLocale } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetAverageHourlyRateReportingWidget } from 'src/apis/reportingWidgetsAPI';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: any, locale: string) => {
  const newRow = data.map((item: any) => ({
    customer: item.customerName,
    project: item.projectName,
    hoursSpent: item.actualHours.toLocaleString(locale),
    averageHourlyRate: item.rate.toLocaleString(locale),
  }));
  return newRow;
};

export const AverageHourlyRateReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const { widgetName, widgetData, isError, isLoading } = useGetAverageHourlyRateReportingWidget();

  const getColumnData = () => [
    {
      headerName: t('TableHeaderCustomer'),
      key: 'customer',
      flex: 3,
    },
    {
      headerName: t('TableHeaderProject'),
      key: 'project',
      flex: 3,
    },
    {
      headerName: t('TableHeaderHoursSpent'),
      key: 'hoursSpent',
      flex: 3,
    },
    {
      headerName: 'AHR',
      key: 'averageHourlyRate',
      flex: 3,
    },
  ];

  return (
    <ReportingWidgetWithTable
      name={translationAnyText(t, widgetName)}
      data={parseRowData(widgetData, locale)}
      columnData={getColumnData()}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default AverageHourlyRateReportingWidget;
