import { GridRenderCellParams, ProgressBar, useGetLocale } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetProjectEconomyHealthReportingWidget } from 'src/apis/reportingWidgetsAPI';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: any, locale: string) => {
  const newRow = data.map((item: any) => ({
    customer: item.customerName,
    project: item.projectName,
    sumBudgetHours: item.sumBudgetHours.toLocaleString(locale),
    sumHoursSpent: item.sumHoursSpent.toLocaleString(locale),
    completionRate:
      item.completionRate === 'NaN' || item.completionRate === 'Infinity' ? 0 : item.completionRate,
    completionRateValue:
      item.completionRate === 'NaN' || item.completionRate === 'Infinity'
        ? '0 %'
        : `${item.completionRate.toLocaleString(locale)} %`,
    status: item.progressStatus.toLowerCase(),
  }));

  return newRow;
};

export const ProjectEconomyHealthReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const { widgetName, widgetData, isError, isLoading } =
    useGetProjectEconomyHealthReportingWidget();

  const getColumnData = () => {
    const columnData = [
      {
        headerName: t('TableHeaderCustomer'),
        key: 'customer',
        flex: 2,
      },
      {
        headerName: t('TableHeaderProject'),
        key: 'project',
        flex: 2,
      },
      {
        headerName: t('TableHeaderHoursBudget'),
        key: 'sumBudgetHours',
        flex: 2,
      },
      {
        headerName: t('TableHeaderHoursSpent'),
        key: 'sumHoursSpent',
        flex: 2,
      },
      {
        headerName: '',
        key: 'completionRate',
        flex: 2,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <ProgressBar barStatus={params.row.status} value={params.value} />
        ),
      },
      {
        headerName: t('TableHeaderCompletionRate'),
        key: 'completionRateValue',
        flex: 2,
        cellClassName: (params: GridRenderCellParams) => params.row.status,
      },
    ];

    return columnData;
  };

  return (
    <ReportingWidgetWithTable
      name={translationAnyText(t, widgetName)}
      data={parseRowData(widgetData, locale)}
      columnData={getColumnData()}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default ProjectEconomyHealthReportingWidget;
