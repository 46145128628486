import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IInvoicedPerMonth } from 'src/apis/types/invoicedPerMonthAPI';

const GET_INVOICED_PER_MONTH_REPORTING_WIDGET_URL_PATH =
  '/api/v2/insights-service/invoiced-per-month';
const GET_INVOICED_PER_MONTH_REPORTING_WIDGET_MSW_STATUS_KEY = 'getInvoicedPerMonthAPI';

const GET_INVOICED_PER_MONTH_REPORTING_WIDGET_KEY = 'GET_INVOICED_PER_MONTH_REPORTING_WIDGET_KEY';

const getInvoicedPerMonthReportingWidget = (): Promise<IInvoicedPerMonth> =>
  getFetch({
    path: GET_INVOICED_PER_MONTH_REPORTING_WIDGET_URL_PATH,
    key: GET_INVOICED_PER_MONTH_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetInvoicedPerMonthReportingWidget = () => {
  const { data, ...restProps } = useQuery([GET_INVOICED_PER_MONTH_REPORTING_WIDGET_KEY], () =>
    getInvoicedPerMonthReportingWidget(),
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    ...restProps,
  };
};
