import { BarChartComponent, BarChartProps } from '@timelog/ui-library';
import ReportingWidgetCard from 'src/components/ReportingWidgetCard/ReportingWidgetCard';
import Loading from 'src/components/MilestoneFeed/components/Loading/Loading';
import Error from 'src/components/MilestoneFeed/components/Error/Error';

interface IReportingWidgetWithChart {
  name: string;
  data: {}[];
  xAxisDataKey: string;
  yAxisDataKey: string;
  barPropsDataKey: string[];
  isEmpty: boolean;
  isError: boolean;
  isLoading: boolean;
}

const parseBarPropsData = (dataKeyList: string[]) => {
  const barProps = dataKeyList.map((key) => ({
    dataKey: key,
  }));
  return barProps;
};

const ReportBarChart = ({ xAxisKey, yAxisKey, data, barProps }: BarChartProps) => (
  // not dynamic-ready atm. Need to define dataKey for each bar
  <div style={{ width: '100%', height: 300 }}>
    <BarChartComponent
      data={data}
      barProps={barProps}
      tooltip
      cartesianGrid
      legend
      xAxisKey={xAxisKey}
      yAxisKey={yAxisKey}
    />
  </div>
);
export const ReportingWidgetWithChart = ({
  name,
  data,
  xAxisDataKey,
  yAxisDataKey,
  barPropsDataKey,
  isEmpty,
  isError,
  isLoading,
}: IReportingWidgetWithChart) => {
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  return (
    <ReportingWidgetCard name={name}>
      {isEmpty ? (
        <div />
      ) : (
        <ReportBarChart
          xAxisKey={xAxisDataKey}
          yAxisKey={yAxisDataKey}
          data={data}
          barProps={parseBarPropsData(barPropsDataKey)} // {[{ dataKey: '2022' }, { dataKey: '2023' }]}
        />
      )}
    </ReportingWidgetCard>
  );
};

export default ReportingWidgetWithChart;
