export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        InvoicedAmount: 2202676.75,
        InvoicedAmountLastYear: 1012925.31,
        Year: 2023,
        LastYear: 2022,
        Month: 'Jan',
      },
      {
        InvoicedAmount: -1062566.02,
        InvoicedAmountLastYear: 760160.95,
        Year: 2023,
        LastYear: 2022,
        Month: 'Feb',
      },
      {
        InvoicedAmount: 43125.0,
        InvoicedAmountLastYear: 1520317.48,
        Year: 2023,
        LastYear: 2022,
        Month: 'Mar',
      },
      {
        InvoicedAmount: -11250.0,
        InvoicedAmountLastYear: -2660548.25,
        Year: 2023,
        LastYear: 2022,
        Month: 'Apr',
      },
      {
        InvoicedAmount: 1520147.65,
        InvoicedAmountLastYear: -192586.87,
        Year: 2022,
        LastYear: 2021,
        Month: 'Sep',
      },
      {
        InvoicedAmount: 537226.31,
        InvoicedAmountLastYear: -778169.91,
        Year: 2022,
        LastYear: 2021,
        Month: 'Oct',
      },
      {
        InvoicedAmount: 1335392.3,
        InvoicedAmountLastYear: -40000.0,
        Year: 2022,
        LastYear: 2021,
        Month: 'Nov',
      },
      {
        InvoicedAmount: -760073.82,
        InvoicedAmountLastYear: 2538703.68,
        Year: 2022,
        LastYear: 2021,
        Month: 'Dec',
      },
    ],
    Type: 'InvoicedPerMonth',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/invoiced-per-month-widget-data?siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
