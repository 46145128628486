import { useGetLocale, convertDateToLocaleString } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetOverdueInvoicesReportingWidget } from 'src/apis/reportingWidgetsAPI';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: any, locale: string) => {
  const newRow = data.map((item: any) => ({
    customer: item.customerName,
    project: item.projectName,
    sum: item.invoiceAmount.toLocaleString(locale),
    dueDate: convertDateToLocaleString(new Date(item.dueDate), locale),
  }));
  return newRow;
};

export const OverdueInvoicesReportingWidget = () => {
  const { t } = useTranslation('reportingWidgets');
  const locale = useGetLocale();
  const { widgetName, widgetData, isError, isLoading } = useGetOverdueInvoicesReportingWidget();

  const getColumnData = () => [
    {
      headerName: t('TableHeaderCustomer'),
      key: 'customer',
      flex: 3,
    },
    {
      headerName: t('TableHeaderProject'),
      key: 'project',
      flex: 3,
    },
    {
      headerName: t('TableHeaderSum'),
      key: 'sum',
      flex: 3,
    },
    {
      headerName: t('TableHeaderDueDate'),
      key: 'dueDate',
      flex: 3,
    },
  ];
  return (
    <ReportingWidgetWithTable
      name={translationAnyText(t, widgetName)}
      data={parseRowData(widgetData, locale)}
      columnData={getColumnData()}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default OverdueInvoicesReportingWidget;
